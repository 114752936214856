import dayjs from "dayjs"

export const formatDate = (date) => {
    if (dayjs(date).isValid()) {
        const formated = dayjs(date).subtract(-3, 'hour').format('DD/MM/YYYY - HH:mm:ss')
        return formated
    }
}

export const formatOnlyDate = (date) => {
    if (dayjs(date).isValid()) {
        return dayjs(date).format('DD/MM/YYYY')
    } else {
        return 'Invalid Date'
    }
}

export const formatOnlyDateUS = (date) => {
    if (dayjs(date).isValid()) {
        return dayjs(date).format('MM/DD/YYYY')
    }
}

export const formatOnlyDateYearFirst = (date) => {
    if (dayjs(date).isValid()) {
        return dayjs(date).format('YYYY-MM-DD')
    }
}

export const formatOnlyDateUnix = (date) => {
    const unixDate = dayjs.unix(date)
    if (dayjs(unixDate).isValid()) {
        return unixDate.format('DD/MM/YYYY')
    }
    return dayjs(date).subtract(-1, 'day').format('DD/MM/YYYY')
}

export const formatOnlyDateTimeStamp = (date) => {
    if (dayjs(date).isValid()) {
        const formated = dayjs(date).unix()
        return formated
    }
}

export const formatDateProjecard = (date) => {
    if (dayjs(date).isValid()) {
        const formated = dayjs(date).format('DD/MM/YYYY - HH:mm:ss')
        return formated
    }
}

export function formatFullDate(dataNaoFormatada) {
    const diasSemana = [
        "Domingo",
        "Segunda-feira",
        "Ter�a-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "S�bado",
    ];
    const meses = [
        "janeiro",
        "fevereiro",
        "mar�o",
        "abril",
        "maio",
        "junho",
        "julho",
        "agosto",
        "setembro",
        "outubro",
        "novembro",
        "dezembro",
    ];
    const data = new Date(dataNaoFormatada);
    const diaSemana = diasSemana[data.getDay()];
    const dia = data.getDate();
    const mes = meses[data.getMonth()];

    return `${diaSemana}, ${dia} de ${mes}`;
}