import axios from "axios";
import JSZip from 'jszip'
import { saveAs } from 'file-saver';
import { formatOnlyDate, formatOnlyDateUS } from "./formatDate";
import {
	dispatchErrorNotification,
	dispatchSuccessNotification,
	dispatchWarningNotification,
} from "./dispatchNotifications";
import { toast } from "react-toastify";

import BackGroundPoolGreen from "../assets/img/BackGroundPoolGreen.svg";
import BackGroundPoolPurple from "../assets/img/BackGroundPoolPurple.svg";
import BackGroundPoolBlue from "../assets/img/BackGroundPoolBlue.svg";

import en from "@/public/locales/en/common.json";
import pt from "@/public/locales/pt/common.json";
import es from "@/public/locales/es/common.json";
import { requestUserData } from "./dataFunctions";

export const getBackground = (index) => {
	const sequencia = [
		BackGroundPoolGreen,
		BackGroundPoolPurple,
		BackGroundPoolBlue,
	];

	if (!index) return BackGroundPoolGreen;
	const indiceCircular = index % sequencia.length;

	return sequencia[indiceCircular];
};

const formatFundData = (data) => {
	return data.map((item, index) => {
		const dateInitial = new Date(item?.captationBegin);
		const dateFinal = new Date(item?.captationEnd);
		const actualDate = new Date();
		return {
			...item,
			emissor: item?.User?.name || "--",
			acronym: item?.code || "--",
			isActive: typeof item?.isActive === "boolean" ? item?.isActive : dateInitial <= actualDate && dateFinal >= actualDate,
			background_pic: getBackground(index) || null,
		};
	})
};

export const fetchFundData = async (accessToken, setFund) => {
	try {
		const fundResponse = await axios.get(process.env.NEXT_PUBLIC_FUND_FIND_USER_URL, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
		});
		if (fundResponse.status === 200) {

			const response = formatFundData(fundResponse.data);
			setFund(response);
			return response;
		}
	} catch (error) {
	}

};
export async function fetchDataAxios(
	limit,
	setProjects,
	endpoint,
	accessToken,
) {
	try {


		const url = `${endpoint}limit=${limit}`;

		const responseData = await axios.get(url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
		});

		const arrayWithoutCNLT = responseData.data?.filter((item) => item.acronym !== "CNLT");

		const arrayInactives = arrayWithoutCNLT.filter(
			(item) => item.isActive === false
		);
		const arrayActives = arrayWithoutCNLT.filter(
			(item) => item.isActive === true
		);


		let newArrayActivesFirst = [...arrayActives, ...arrayInactives];


		setProjects(newArrayActivesFirst);

		return newArrayActivesFirst;
	} catch (error) {
		console.error("Erro ao carregar os projetos", error);
	}
}

export const fetchGetIssuerProject = async (id, endpoint, setOwner, token) => {
	axios
		.get(`${endpoint}${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		})
		.then((resp) => {
			// const projectOwner = response.data.name
			setOwner(resp.data.name);
		});
};

export async function getProjectsUnlimited(setProjects, endpoint) {
	const response = await axios.get(`${endpoint}`, {
		headers: {
			"Content-Type": "application/json",
		},
	});

	setProjects(response.data);
}

export async function fetchDataIdAxios(id, setProject, endpoint) {
	const response = await axios.get(`${endpoint}${id}`, {
		headers: {
			"Content-Type": "application/json",
		},
	});

	setProject(response.data);
}

export async function fetchFundDataIdAxios(id, setProject, index) {
	try {
		const acessToken = localStorage.getItem("accessToken");
		const response = await axios.get(
			`${process.env.NEXT_PUBLIC_PATTERN_FUND}/${id}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${acessToken}`,
				},
			}
		);

		if (response.status === 200) {
			return setProject((preves) => {
				return {
					...preves,
					backgroundProjectImage: getBackground(index || 0),
					name: response.data.name,
					emissor: response.data?.User?.name,
					acronym: response.data.code,
				}
			});
		}
	} catch (error) {
		console.error("Esse item n�o � um fundo");
	}

}

export async function fetchDataIdAxiosWithFunction(
	id,
	setProject,
	handleSetInfoUpdate,
	endpoint
) {
	const response = await axios.get(`${endpoint}${id}`, {
		headers: {
			"Content-Type": "application/json",
		},
	});

	const data = response.data;
	const {
		nome,
		rentabilidade,
		acronimo,
		dataLancamento,
		descricao,
		resumo,
		tipoToken,
		faseDoProjeto,
	} = data;

	const dateFormated = formatOnlyDate(dataLancamento);

	handleSetInfoUpdate(
		nome,
		acronimo,
		tipoToken,
		dateFormated,
		resumo,
		descricao,
		rentabilidade,
		faseDoProjeto
	);
	setProject(data);
	return faseDoProjeto;
}

export async function fetchDataUserInfo(
	accessToken,
	setDataUser,
	router,
	endpoint
) {
	var config = {
		method: "get",
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Content-Type": "application/json",
		},
	};

	if (accessToken) {
		const response = await fetch(endpoint, config).catch((error) =>
			console.error("Problema ao carregar o usu�rio", error)
		);
		const data = await response.json();
		const dataUser = data.user;

		setDataUser(dataUser);

		if (response.status === 200 || response.status === 201) {
			localStorage.setItem("logado", JSON.stringify(data.user));
		}
		if (response.status === 400) {
			if (
				(accessToken && window.location.pathname === "/") ||
				window.location.pathname === "/quem-somos"
			) {
				toast.error("Sua sess�o expirou. Por favor, fa�a login novamente");
			}
			localStorage.removeItem("logado");
			if (
				(accessToken && window.location.pathname === "/") ||
				window.location.pathname === "/quem-somos"
			) {
				toast.error("Sua sess�o expirou. Por favor, fa�a login novamente.");
			}
		}

		if (response.status === 400 && window.location.href.includes("perfil")) {
			window.location.href = "/quem-somos";
		}

		if (!dataUser?.userAdmin && (router.asPath.includes("admin") || router.asPath.includes("controle"))) {
			router.push("/notfound");
		}

		return {
			data,
		};
	}

	return {};
}

export async function fetchUserHistoryinfo(
	accessToken,
	setHistoryUser,
	endpoint
) {
	var config = {
		method: "get",
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Content-Type": "application/json",
		},
	};

	try {
		const response = await fetch(endpoint, config);

		const data = await response.json();

		const historyFiltered = data?.filter((a) => a.type !== "Tax Payment");

		setHistoryUser(
			historyFiltered.sort(
				(a, b) => new Date(b.created_at) - new Date(a.created_at)
			)
		);
	} catch (error) {
		console.error("Problema ao carregar o hist�rico do usu�rio");
		console.error(error);
	}
}

export async function fetchRequestPix(
	accessToken,
	quantity,
	endpoint,
	kycLevel
) {

	let formattedValue = quantity
	if (formattedValue.includes(".")) {
		formattedValue = quantity.replace(".", "");
	}
	formattedValue = formattedValue.replace(",", ".");
	const intValue = parseFloat(formattedValue);
	const maxDepositValueByKyc = kycLevel === 1 ? 99000 : kycLevel === 2 ? 199000 : kycLevel === 3 ? Infinity : 0;

	try {

		if (intValue > maxDepositValueByKyc) {
			console.log(intValue, maxDepositValueByKyc)
			throw new Error('Deposit amount exceeds the limit. Please do a new KYC check!');
		}

		const response = await axios
			.post(endpoint, {
				quantity: formattedValue
			},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				})

		const textContent = response.data.data.textContent;
		const pixId = response.data.data.pixId;
		localStorage.setItem("pixId", pixId);

		return { pixId, textContent, };

	} catch (error) {
		if (error?.message.includes('KYC check!')) {
			console.log(error?.message)
			dispatchErrorNotification(
				toast,
				"O valor do dep�sito ultrapassa o limite. Por favor, fa�a uma nova verifica��o KYC.",
				false
			);
		} else {
			console.log(error)
		}
	}
}

export async function getStatusBuyToken(endpoint, id, token) {
	let statusReturn = "processing", type;
	console.log("endpoint: ", `${endpoint}${id}`)
	await axios
		.request({
			url: `${endpoint}${id}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
		.then((resp) => {
			const status = resp.data.status;
			type = resp.data.type;
			const intervalCheckStatusBuy = setInterval(async () => {
				if (status === "success") {
					clearInterval(intervalCheckStatusBuy);
					statusReturn = "success";

					localStorage.removeItem("idTransaction");
				} else if (status === "failed") {
					clearInterval(intervalCheckStatusBuy);
					statusReturn = "failed";

					localStorage.removeItem("idTransaction");
				}
			}, 5000);
		});
	return { status: statusReturn, type };
}

export async function requestBuyToken(
	accessToken,
	quantity,
	endpoint,
	projectId,
	wallet_address,
	isSuccess
) {
	const formatQuantity = String(parseFloat(quantity.replace(/\./g, '')))
	let dataBody
	if (String(endpoint).includes("fund")) {

		dataBody = JSON.stringify({
			amount: formatQuantity,
			fundId: projectId
		})
	} else {
		dataBody = JSON.stringify({
			wallet: wallet_address,
			quantity: formatQuantity,
			projectId,
		});
	}
	try {
		if (formatQuantity < 1) {
			throw new Error("The minimum investment is 1$")
		}

		const response = await axios
			.request({
				method: "POST",
				url: endpoint,
				data: dataBody,
				headers: {
					Authorization: `Bearer ${accessToken}`,
					"Content-Type": "application/json",
				},
			})
		console.log(response)
		const idTransaction = response.data.transactionBuy
			? response.data.transactionBuy.id
			: response.data.transaction.id;

		localStorage.setItem("idTransaction", idTransaction);
		return response.status;
	} catch (error) {
		console.log(error);
		return 400;

	};
}

export const buyFund = async (
	amount,
	fundId,
	setHashConfirm,
	setBuyConfirmed,
	setHiddenBuyProject,
	setBuyCoinlivre,
	setValueSaldo
) => {
	const acessToken = localStorage.getItem("accessToken");
	const url = process.env.NEXT_PUBLIC_FUND_USER_INVEST;

	const response = await axios.post(
		url,
		{ amount, fundId },
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${acessToken}`,
			},
		}
	);

	if (response.status === 200) {
		setBuyConfirmed(true);
		setHashConfirm("");
		localStorage.setItem(
			"idTransaction",
			JSON.stringify(response.data.transactionBuy.id)
		);
		setBuyConfirmed(true);
		setHiddenBuyProject(true);
		setValueSaldo(true);
		setBuyCoinlivre(true);
	}
};

export const handleRequestLoginRefactor = async (
	setWaiting,
	setError,
	email,
	error = false,
	setShowEmailSent,
	endpoint
) => {
	setWaiting(true);
	const emailFormatted = String(email).toLowerCase();
	axios
		.request({
			method: "POST",
			url: endpoint,
			data: {
				email: emailFormatted,
			},
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
		})
		.then((resp) => {
			if (resp.status === 200) {
				setWaiting(false);
				setShowEmailSent(true);
			}
		})
		.catch((error) => console.log(error));
};

export const sendInvitationEmailToFund = (
	code,
	email,
	setWaiting,
	setShowEmailSent
) => {
	const url = String(process.env.NEXT_PUBLIC_USER_LOGIN_FUND);
	setWaiting(true);
	const emailFormatted = String(email).toLowerCase();
	axios
		.request({
			method: "POST",
			url,
			data: {
				email: emailFormatted,
				inviteCode: code,
			},
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
		})
		.then((resp) => {
			if (resp.status === 200) {
				setWaiting(false);
				setShowEmailSent(true);
			}
		})
		.catch((error) => {
			console.log({ error: error });
			toast?.error("Seu convite � inv�lido.");
			setWaiting(false);
		});
};

export const handleUserRequest = async (
	setWaiting,
	setFeedback,
	setError,
	email,
	error = false,
	endpoint
) => {
	setWaiting(true);

	if (email && !error) {
		setWaiting(true);

		axios
			.request({
				method: "POST",
				url: endpoint,
				data: {
					email,
				},
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
				},
			})
			.then((resp) => {
				if (resp.status === 200) {
					setWaiting(false);
					setFeedback(true);
				}
			})
			.catch((error) => console.log(error));
	} else {
		setError(true);
	}
};

export const handleGetUserInfo = async (
	setSuccess,
	setPreloaded,
	router,
	endpoint
) => {
	const token = localStorage.getItem("accessToken");

	const config = {
		method: "post",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + token,
		},
	};

	await fetch(endpoint, config).then((resp) => {
		console.log(resp);
		console.log(resp.ok);
		if (resp.ok) {
			setSuccess(true);
			setPreloaded(true);
			setTimeout(() => {
				router.push("/");
			}, 3000);
		} else {
			setPreloaded(true);
		}
	});
};

export const handleUserRequestRegister = async (
	setWaiting,
	name,
	cpf,
	date,
	nationality,
	passportNumber,
	setSuccess,
	selectedOption,
	setDoubleCpfError,
	router,
	endpoint,
	setMsg
) => {
	const token = localStorage.getItem("accessToken");

	const { locale } = router;

	const newDate =
		locale === "en" ? formatOnlyDateUS(date) : formatOnlyDate(date);

	function handleLanguage(locale) {
		switch (locale) {
			case "en":
				return en.vocePrecisaVerificarParaRegistrar;
			case "pt":
				return pt.vocePrecisaVerificarParaRegistrar;
			case "es":
				return es.vocePrecisaVerificarParaRegistrar;
			default:
				return pt.vocePrecisaVerificarParaRegistrar;
		}
	}

	const data =
		selectedOption === "PASSPORT"
			? {
				name,
				birthdate: newDate,
				country: nationality,
				passportNumber,
			}
			: {
				name,
				cpf: String(cpf).replaceAll(".", "").replaceAll("-", ""),
				birthdate: newDate,
			};

	setWaiting(true);
	if (token && name && date) {
		axios
			.request({
				method: "POST",
				url: endpoint,
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
					Authorization: "Bearer " + token,
				},
				data,
			})
			.then(async (resp) => {
				if (resp.status === 201) {
					if (selectedOption === "PASSPORT") {
						localStorage.setItem(
							"verificationLevel",
							JSON.stringify("international")
						);

						dispatchWarningNotification(toast, handleLanguage(locale), false);
						setTimeout(() => {
							router.push("/metamap");
						}, 5000);
					} else {
						setWaiting(false);
						setSuccess(true);
						setTimeout(() => {
							router.push("/perfil");
						}, 3000);
					}
				}
			})
			.catch((error) => {
				setMsg(error.message);
				setWaiting(false);
				setDoubleCpfError(true);
			});
	}
};

export const handleGuestUserRegistration = async (
	name,
	code,
	cpf,
	birthdate,
	setWaiting,
	setSuccess,
	setMsg,
	router,
	setLoggedIn
) => {
	const token = localStorage.getItem("accessToken");
	const url = String(process.env.NEXT_PUBLIC_GUEST_USER_REGISTRATION);

	setWaiting(true);

	axios
		.post(
			`${url}/${code}`,
			{
				name,
				cpf,
				birthdate,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
		.then(async (resp) => {
			if (resp.status === 201) {
				setWaiting(false);
				setSuccess(true);
				setLoggedIn(true);
				setTimeout(() => {
					router.push("/perfil");
				}, 3000);
			}
		})
		.catch((error) => {
			console.log(error);
			setMsg(error.message);
			setWaiting(false);
		});
};

export const handleGetUserInfoCadastro = async (token, router, endpoint) => {
	const config = {
		method: "post",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + token,
		},
	};

	await fetch(endpoint, config).then((resp) => {
		if (resp.ok || resp.status === 200) {
			router.push("/perfil");
		} else {
			router.push("/registrar-se");
		}
	});
};

export const handleUserSession = async (
	setUserInfo,
	setLoggedIn,
	token,
	router,
	userInfoEndpoint,
	userCadastroEndpoint
) => {
	if (token) {
		var config = {
			method: "post",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		};
		const response = await fetch(userInfoEndpoint, config);

		const data = await response.json();

		if (data.nome) {
			localStorage.setItem("logado", JSON.stringify(data));
			setUserInfo(data);
			setLoggedIn(true);
			handleGetUserInfoCadastro(token, router, userCadastroEndpoint);
		}
	}
};
export default async function getProjectById(
	endpoint,
	project_id,
	accessToken
) {
	let response = await fetch(`${endpoint}/${project_id}`, {
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: `Bearer ${accessToken}`,
		},
	});
	return await response.json();
}
export const uploadProfilePhoto = async (photoFile, accessToken, endpoint) => {
	let formData = new FormData();
	formData.append("file", photoFile);

	axios
		.post(endpoint, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.then((resp) => {
			if (resp.status === 201) {
				dispatchSuccessNotification(
					toast,
					"Imagem de perfil atualizada com sucesso! A p�gina ir� recarregar.",
					true
				);
			}
		});
};

export const uploadBackgroundPhoto = async (
	photoFile,
	accessToken,
	endpoint
) => {
	let formData = new FormData();
	formData.append("file", photoFile);

	await axios
		.post(endpoint, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.then((resp) => {
			if (resp.status === 201) {
				dispatchSuccessNotification(
					toast,
					"Background atualizado com sucesso! A p�gina ir� recarregar.",
					true
				);
			}
		});
};

export const handleAuthLogin = async (
	setUserInfo,
	setLoggedIn,
	token,
	router,
	envs
) => {
	await axios
		.post(envs.NEXT_PUBLIC_AUTH_LOGIN, {
			tokenId: token,
		})
		.then((response) => {
			const data = response.data;

			if (data.accessToken && data.refreshToken) {
				localStorage.setItem("accessToken", data.accessToken);
				localStorage.setItem("refreshToken", data.refreshToken);
				setUserInfo(data.usuarioInfo);
				setLoggedIn(true);
				return handleGetUserCadastro(router, envs.NEXT_PUBLIC_GET_USER_CADASTRO);
			}
		})
		.catch((error) => console.error("Login do usu�rio", error));
};

const handleGetUserCadastro = async (router, endpoint) => {
	const token = localStorage.getItem("accessToken");
	axios
		.get(endpoint, {
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
				Authorization: "Bearer " + token,
			},
		})
		.then((resp) => {
			if (resp.status === 200) {
				return router.push("/perfil");
			} else {
				return router.push("/registrar-se");
			}
		})
		.catch((error) => router.push("/registrar-se"));
};

export const handleAuthLoginUserFund = async (
	setUserInfo,
	setLoggedIn,
	token,
	envs,
	router,
) => {
	const response = await axios
		.post(envs.NEXT_PUBLIC_AUTH_LOGIN, {
			tokenId: token,
		})
		.then(({ data }) => {
			if (data.accessToken && data.refreshToken) {
				localStorage.setItem("accessToken", data.accessToken);
				localStorage.setItem("refreshToken", data.refreshToken);
				setUserInfo(data.usuarioInfo);
				setLoggedIn(true);
				return data;
			}
		})
		.catch((error) => {
			const errorCode = error.response.status;
			if (errorCode === 400) {
				toast?.error("Erro no Login do usu�rio.");
				localStorage.removeItem("accessToken");
				localStorage.removeItem("refreshToken");
				router.push("/");
			}
		});

	if (response) {
		return response;
	}
};

export const uploadDocumentsProject = async (
	id,
	files,
	accessToken,
	endpoint
) => {
	const formData = new FormData();

	formData.append("id", id);

	for (let i = 0; i < files.length; i++) {
		formData.append(`file${i + 1}`, files[i][0]);
	}

	const response = await axios.patch(endpoint, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${accessToken}`,
		},
	});

	return response.status;
};

export const validateInvitation = async (invite, token) => {
	const url = process.env.NEXT_PUBLIC_VALIDATE_INVITATION;
	const response = await axios.post(
		url,
		{
			inviteCode: invite,
		},
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}
	);

	if (response.status === 201) {
		return { success: true };
	}

	return null;
};

export const uploadDataFormCreateProject = async (
	data,
	accessToken,
	setWaiting,
	endpoint
) => {
	const dataJson = JSON.stringify(data);

	setWaiting(true);
	const {
		data: { id },
	} = await axios.post(endpoint, dataJson, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	});
	setWaiting(false);

	return {
		responseID: id,
	};
};

export const uploadBackgroundProject = async (
	id,
	backgroundFile,
	accessToken,
	endpoint
) => {
	const formData = new FormData();

	formData.append("id", id);

	formData.append("file", backgroundFile);

	await axios
		.patch(endpoint, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.then((response) => {
			if (response.status === 200) {
				localStorage.setItem("updateBalance", "true");
			}
		});
};

export const deleteProject = async (id, endpoint) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	const response = await axios.delete(endpoint + id, config);

	return response.status;
};

export const updateProject = async (id, data, endpoint) => {
	const bodyStringfy = JSON.stringify(data);

	const response = await axios.patch(endpoint + id, bodyStringfy, {
		headers: {
			"Content-Type": "application/json",
		},
	});

	return response.status;
};

export const getEmissor = async (limit, endpoint) => {
	const response = await fetch(endpoint + limit);

	const data = await response.json();

	return data;
};

export const setVerificationUser = async (
	verificationLevel,
	accessToken,
	endpoint
) => {
	let verificationString = String(verificationLevel);
	let verificationNumber = 0;
	if (verificationString === "1_2") {
		verificationString = "2";
	} else if (verificationString === "1_2_3") {
		verificationString = "3";
	} else if (verificationString === "2_3") {
		verificationString = "3";
	}
	verificationNumber = Number(verificationString);
	await axios
		.post(
			endpoint,
			{
				verificationLevel: verificationNumber,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
			}
		)
		.then((resp) => console.log(resp));
};

export const getVerificationStatusUser = (accessToken, setArray, endpoint) => {
	fetch(endpoint, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	})
		.then((resp) => resp.json())
		.then((data) => setArray(data));
};

export const sendEmailRequest = async (accessToken, endpoint) => {
	axios({
		method: "POST",
		url: endpoint,
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
};

export const approvedEmailRequest = async (
	accessToken,
	verificationLevel,
	endpoint
) => {
	axios
		.post(
			endpoint,
			{
				verificationLevel,
			},
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		)
		.then(() => {
			axios.put({
				method: "PUT",
				url: envs.NEXT_PUBLIC_VERIFICATION_USER,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				data: {
					auth_email: false,
				},
			});
		});
};

export const rejectEmailRequest = async (
	accessToken,
	verificationStatus,
	endpoint
) => {
	axios.post(
		endpoint,
		{
			verificationStatus,
		},
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);
};

export const sendAnalyzisEmailWithdraw = async (accessToken, endpoint) => {
	axios
		.request({
			method: "POST",
			url: endpoint,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.then(() => console.log("Email de an�lise enviado"));
};

export const sendApprovedEmailWithdraw = async (accessToken, endpoint) => {
	axios.request({
		method: "POST",
		url: endpoint,
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
};

export const sendRejectEmailWithdraw = async (accessToken, endpoint) => {
	axios.request({
		method: "POST",
		url: endpoint,
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
};

export const sendApprovedEmailInternational = async (accessToken, endpoint) => {
	axios
		.request({
			method: "POST",
			url: endpoint,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.then(() => {
			console.log("Email de aprova��o internacional enviado");
			localStorage.setItem("verificationApproved", "true");
		});
};

export const sendAnalyzisEmailInternational = async (accessToken, endpoint) => {
	await axios
		.request({
			method: "POST",
			url: endpoint,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.then(() => console.log("Email de an�lise internacional enviado"));
};

export const sendRejectedEmailInternational = async (accessToken, endpoint) => {
	axios
		.request({
			method: "POST",
			url: endpoint,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
		.then(() => console.log("Email de aprova��o internacional enviado"));
};

export const transferAccount = async (
	accessToken,
	key,
	auth_key,
	endpoint,
	value,
	setConfirmation,
	setShowMsg
) => {
	const body = {
		transferType: key,
		value: String(value),
	};

	axios
		.post(endpoint, body, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				key: auth_key,
			},
		})
		.then((res) => {
			setShowMsg(true);
			setConfirmation(true);
			setTimeout(() => {
				setShowMsg(false);
			}, 3700);
		})
		.catch((err) => {
			setShowMsg(true);
			setConfirmation(false);
			setTimeout(() => {
				setShowMsg(false);
			}, 3700);
		});
};

export function withdrawVerify(
	endpoint,
	token,
	router,
	pixKey,
	envs,
	pixTypeKey
) {
	const endpointBank = envs.NEXT_PUBLIC_WITHDRAW_BANK;
	let userCPF = "";
	const body = {
		pixKey:
			pixTypeKey === "CPF / CNPJ"
				? String(pixKey)
					.replaceAll(".", "")
					.replaceAll("-", "")
					.replace(" ", "")
				: String(pixKey).replace(" ", "").replace("-", ""),
	};
	axios
		.post(endpointBank, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		.then((resp) => {
			userCPF = resp.data.accountHolder.taxId;

			axios
				.get(endpoint, {
					headers: {
						Authorization: `Bearer ${token}`,
						cpf: String(userCPF).replaceAll(".", "").replaceAll("-", ""),
					},
				})
				.then((resp) => {
					if (resp.status === 200) {
						router.push("/verificacao-biometrica");
					}
				})
				.catch((data) => {
					const errorCode = data.response.data.message;
					switch (errorCode) {
						case "ERROR01":
							return toast.error(
								"H� um saque pendente, por favor aguarde a conclus�o do saque anterior."
							);
						case "ERROR02":
							return toast.error(
								"Voc� est� usando um CPF diferente do que voc� cadastrou, por uma quest�o de seguran�a, o saque s� � possivel para a conta do mesmo CPF cadastrado"
							);
					}
				});
		});
}

export const requestWithdraw = async (
	token,
	value,
	pixKey,
	envs,
	setVerified,
	setSteps,
	stopVideo,
	setMsg
) => {
	const endpointWithdraw = envs.NEXT_PUBLIC_WITHDRAW;
	const endpointBank = envs.NEXT_PUBLIC_WITHDRAW_BANK;
	const numberValue = Number(value);

	let responseBank;
	let responseBankFormatted;
	const body = {
		pixKey,
	};
	axios
		.post(endpointBank, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		.then((resp) => {
			responseBank = resp.data;
			responseBankFormatted = {
				key: responseBank.key,
				keyType: responseBank.keyType,
				pspIdentification: responseBank.pspIdentification,
				taxId: responseBank.accountHolder.taxId,
				name: responseBank.accountHolder.name,
				branch: responseBank.accountAdditionalInformation.branch,
				accountNumber: responseBank.accountAdditionalInformation.accountNumber,
				accountType: responseBank.accountAdditionalInformation.accountType,
			};
			const bodywithValue = {
				...responseBankFormatted,
				value: numberValue,
			};

			axios
				.post(endpointWithdraw, bodywithValue, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then((resp) => {
					setVerified(true);
					setSteps(7);
					stopVideo();
					if (resp.status === 201 || resp.status === 200) {
						localStorage.setItem("updateBalance", "true");
						setVerified(true);
						setTimeout(() => {
							setSteps(5);
						}, 2000);
						axios
							.request({
								method: "GET",
								url: envs.NEXT_PUBLIC_VERIFICATION_USER,
								headers: {
									Authorization: `Bearer ${token}`,
								},
							})
							.then((resp) => {
								if (resp.data.length > 0) {
									axios
										.put(
											`${envs.NEXT_PUBLIC_VERIFICATION_USER}`,
											{
												auth_withdraw: false,
											},
											{
												headers: {
													Authorization: `Bearer ${token}`,
												},
											}
										)
										.then((resp) => {
											console.log(resp);
										});
								} else {
									axios.post(
										envs.NEXT_PUBLIC_VERIFICATION_USER,
										{
											auth_withdraw: false,
										},
										{
											headers: {
												Authorization: `Bearer ${token}`,
											},
										}
									);
								}
							});
					} else {
					}
				})
				.catch((data) => {
					console.log(data);
					if (data.response.data.code === "ERROR-03") {
						setMsg("ao");
						setSteps(7);
						setTimeout(() => {
							setSteps(9);
						}, 2000);
						setIsPixCooldown(true);
						setVerified(false);
						stopVideo();
					} else {
						setSteps(5);
						setVerified(false);
						stopVideo();
						toast.error("Aconteceu algum erro.");
					}
					setVerified(false);
				});
		})
		.catch((err) => console.log(err));
};

export function getProjectPaymentsData(
	endpoint,
	projectID,
	setProjectPayments
) {
	const endpointFormatted = endpoint + projectID;
	axios
		.request({
			method: "GET",
			url: endpointFormatted,
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((resp) => {
			if (resp.status === 200) {
				setProjectPayments(resp.data);
				// if (!balanceUpdate) {
				//   router.reload()
				// }
			} else {
			}
		})
		.catch((error) => {
			console.error("Houve um erro ao atualizar o Balance do usu�rio", error);
		});
}

export const updateBalanceUser = (endpoint, token, router) => {
	const balanceUpdate = sessionStorage.getItem("balanceUpdate");

	axios
		.request({
			method: "POST",
			url: endpoint,
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		})
		.then((resp) => {
			if (resp.status === 200) {
				console.log("SALDO ATUALIZADO COM SUCESSO!");
				return sessionStorage.setItem("balanceUpdate", "true");
			}
			return localStorage.setItem("balanceUpdate", "false");
		})
		.catch((error) => {
			localStorage.setItem("balanceUpdate", "false");
			console.error("Houve um erro ao atualizar o Balance do usu�rio", error);
		});
};

export const handleMintCnlt = (token, pixID, endpoint) => {
	axios
		.request({
			method: "POST",
			url: endpoint,
			timeout: 120000,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			data: {
				pixID,
			},
		})
		.then((resp) => console.log("RESPONSE DO MINT", resp));
};

export const refund = (envs, value, correlationID, transactionID) => {
	axios.request({
		method: "POST",
		url: envs.NEXT_PUBLIC_REFUND_PIX,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		body: {
			value,
			correlationID,
			transactionID,
		},
	});
};

export const checkStatusPix = async (
	endpoint,
	setErrorCpfCnlt,
	setBuyConfirmed,
	router,
	envs
) => {
	const pixId = localStorage.getItem("pixId");
	let token = localStorage.getItem("accessToken");
	const interval = setInterval(async () => {
		axios
			.request({
				url: `${endpoint}/${pixId}`,
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((resp) => {
				let logado = JSON.parse(localStorage.getItem("logado"));
				let cpf = logado.cpf;
				if (resp.status === 200) {
					const { status, payedBy } = resp.data;

					// console.log('CPFS VALIDOS', payedBy === cpf)
					if (status === "payed") {
						setBuyConfirmed(true);
						sessionStorage.setItem("beforePath", router.asPath);
						clearInterval(interval);

						if (payedBy === cpf) {
							handleMintCnlt(token, pixId, envs.NEXT_PUBLIC_MINT_CNLT_PIX);
						} else {
							refund(envs);
						}
						localStorage.removeItem("pixId");
						setTimeout(() => {
							router.reload();
						}, 5000);
					} else if (status === "created") {
						setErrorCpfCnlt(false);
						return false;
					} else if (status === "refunded") {
						clearInterval(interval);
						localStorage.removeItem("pixId");
						return setErrorCpfCnlt(true);
					} else if (status === "failed") {
						clearInterval(interval);
						localStorage.removeItem("pixId");
						return setErrorCpfCnlt(true);
					}
				}
			})
			.catch((error) => {
				console.error("Houve algum problema com a transa��o do Pix");
				clearInterval(interval);
				setErrorCpfCnlt(false);
				console.log(error);
			});
	}, 5000);
};

export const registerCnpjProfile = (envs) => {
	const options = {
		method: "POST",
		url: "https://api.getmati.com/govchecks/v1/br/cnpj-extended",
		headers: {
			accept: "application/json",
			"Content-Type": "application/json",
			authorization:
				"Basic NjQzODY3NjliZDZjNDcwMDFiOGNhNmQ3OkRLNlBCVkpHNk83T01FR1VKSTNOTFJISFdZQjRJS0dF",
		},
		data: { cnpj: "49.481.590/0001-00", callbackURL: "string" },
	};

	axios
		.request(options)
		.then((resp) => console.log("RESPONSE METAMAP LOGIN", resp));
};

export const getCnpjInfos = (
	token,
	setSuccess,
	setWaiting,
	setErrorActiveCnpj,
	setErrorEmailCnpj,
	setDoubleErrorCnpj,
	router,
	cnpj,
	envs
) => {
	let cnpjWithoutDots = "";
	cnpjWithoutDots = cnpj
		.replaceAll(".", "")
		.replaceAll("-", "")
		.replaceAll("/", "");

	setWaiting(true);
	axios
		.request({
			method: "POST",
			url: envs.NEXT_PUBLIC_GET_CNPJ_INFO,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			data: {
				cnpj: cnpjWithoutDots,
			},
		})
		.then((resp) => {
			setWaiting(false);
			if (resp.status === 201) {
				setSuccess(true);
				setTimeout(() => {
					router.push("/perfil");
				}, 5000);
			}
		})
		.catch((error) => {
			const message = error.response.data.message;
			if (
				message ===
				"Email used is not the same in CNPJ registry or Whitelisted email/cnpj has been registered"
			) {
				setWaiting(false);
				setErrorEmailCnpj(true);
			} else if (message === "Error to send CNPJ to external API") {
				setWaiting(false);
				setErrorActiveCnpj(true);
			} else if (message === "CNPJ has already registered") {
				setWaiting(false);
				setDoubleErrorCnpj(true);
			}
		});
};

export const getTransactionsReceivable = async (endpoint, setUserTransactions, accessToken) => {

	try {
		const response = await axios.request({
			method: "GET",
			url: endpoint,
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		})

		let successTransactions = response.data.filter(transaction => (transaction.type !== "Tax Payment" && transaction.status === "success"));
		successTransactions = successTransactions.sort((a, b) => {
			return new Date(b.created_at) - new Date(a.created_at);
		});
		successTransactions = successTransactions.map(({ Project, Fund, quantity, type, tx, created_at }) => {
			const newDate = new Date(new Date(created_at).getTime() - (180 * 60000)).toISOString()
			return {
				date: {
					day: newDate.split('T')[0],
					time: newDate.split('T')[1].slice(0, 5).replace(":", "h")
				},
				projectName: Project ? Project.name : Fund.name,
				acronym: Project ? `#${Project.acronym}` : Fund.code,
				quantity,
				type: `#${type}`,
				hash: tx,
			}
		})

		setUserTransactions(successTransactions);

	} catch (error) {
		console.log(error);
	}
};

export async function saveImageUser(endpoint, token, imageUrl) {
	axios.request({
		method: "PATCH",
		url: endpoint,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: {
			imageUrl,
		},
	});
}

export async function getImageUser(endpoint, token, router, setSteps) {
	const response = await axios.request({
		method: "GET",
		url: endpoint,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	const imageUrl = response.data.user.imageUrl;
	if (imageUrl === null) {
		setSteps(11);
		localStorage.setItem("verificationLevel", "1");
	} else {
		setSteps(3);
	}

	return imageUrl;
}

export const downloadUserDocuments = async (endpoint, id, token, name) => {
	try {
		// console.log(`endpoint: ${endpoint} \n id: ${id} \n token: ${token} \n name: ${name}`)

		const response = await axios.request({
			method: "GET",
			url: `${endpoint}${id}`,
			responseType: 'arraybuffer',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		const zip = new JSZip();
		const data = await zip.loadAsync(response.data);
		console.log(data);

		if (Object.keys(data.files).length > 0) {
			// O arquivo ZIP cont�m arquivos, prosseguir com o download
			const blob = new Blob([response.data], { type: 'application/zip' });
			saveAs(blob, `docs-${name}.zip`);
		} else {
			// O arquivo ZIP est� vazio, n�o fazer o download
			console.log("O arquivo ZIP est� vazio.");
		}

	} catch (error) {
		console.log("Erro ao baixar o arquivo:", error);
	}


}

export const validateUserDocuments = async (endpoint, id, token,) => {
	try {

		const response = await axios.request({
			method: "PATCH",
			url: `${endpoint}${id}`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})

		console.log(response);
	} catch (error) {
		console.log(error)
	}
}