import { getDateMask } from "@/assets/js/util/masks";
import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";

type Props = {
	endpoint: string;
	setLoading: any;
	token: string;
};
type PayProps = {
	endpoint: string;
	token: string;
	id: string;
	setIsTransactionAllowed: Dispatch<SetStateAction<boolean>>;
	setPaymentIsPaid: Dispatch<SetStateAction<string[]>>;
};
type RegisterProps = {
	name: string;
	birth: Date;
	email: string;
	cnpj: string;
	address: string;
	cpf: string;
	address_number: string;
	address_complement?: string;
	file1: any;
	file2: any;
	file3: any;
	file4: any;
	companyName: string;
	router: any;
	setDisableButton: Dispatch<SetStateAction<boolean>>;
};

type CnpjProps = {
	dataForm: {
		cnpj: string;
		ownerName: string;
		ownerCpf: string;
		ownerBirth: string;
		cellNumber: string;
	};
	file1: any;
	file2: any;
	file3: any;
	file4: any;
	setWaiting: Dispatch<SetStateAction<boolean>>;
	router: any;
	setSuccess: Dispatch<SetStateAction<boolean>>;
	url: string;
}
export async function getPaymentsData({ endpoint, setLoading, token }: Props) {
	setLoading(true);

	try {
		const response = await axios.get(endpoint, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		setLoading(false);
		return response.data;
	} catch (err) {
		setLoading(false);
		return null;
	}
}
export async function payProject({
	endpoint,
	token,
	id,
	setIsTransactionAllowed,
	setPaymentIsPaid
}: PayProps) {
	const endpointFormatted = endpoint + id;
	try {
		await axios.post(
			endpointFormatted,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);

		setIsTransactionAllowed(true);
		setPaymentIsPaid((prev: any) => [...prev, id]);
		return true;
	} catch (err) {
		setIsTransactionAllowed(false);
		return false;
	}
}
export function registerFund({
	name,
	cpf,
	cnpj,
	birth,
	email,
	address,
	address_number,
	address_complement,
	companyName,
	file1,
	file2,
	file3,
	file4,
	router,
	setDisableButton,
}: RegisterProps) {
	const formData = new FormData();
	formData.append("files", file1);
	formData.append("files", file2);
	formData.append("files", file3);
	formData.append("files", file4);
	let address_complement2 =
		address_complement !== "" ? address_complement : "nao";
	const accessToken = localStorage?.getItem("accessToken");

	const body = {
		name,
		email,
		cnpj,
		managerName: companyName,
		managerCpf: cpf,
		managerBirth: getDateMask(birth),
		address,
		address_number,
		address_complement: address_complement2,
	};

	return axios.post(String(process.env.NEXT_PUBLIC_REGISTER_FUND_MANAGER), body, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	}).then((data) => {
		setDisableButton(true)
		const managerId = data.data?.user?.id;
		axios
			.patch(
				`${process.env.NEXT_PUBLIC_DOCUMENTS_FUND}/${managerId}`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${accessToken}`,
					},
				}
			)
			.then((data) => {
				return router.push("/perfil")
			})
			.catch((err) => {
				setDisableButton(false)
			});
		;
	}).catch((err) => {
		setDisableButton(false)
	})
}
export async function registerCnpj({
	dataForm,
	file1,
	file2,
	file3,
	file4,
	setWaiting,
	router,
	setSuccess,
	url
}: CnpjProps) {
	const formData = new FormData();
	formData.append("files", file1);
	formData.append("files", file2);
	formData.append("files", file3);
	formData.append("files", file4);

	const accessToken = localStorage?.getItem("accessToken");

	axios.post(url, dataForm, {
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + accessToken,
		},
	}).then((response) => {
		setWaiting(true);
		const userId = response.data?.user?.id;
		axios.patch(`${process.env.NEXT_PUBLIC_USER_CNPJ_DOCUMENT}/${userId}`, formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${accessToken}`,
				},
			}).then((data) => {
				setSuccess(true);
				setTimeout(() => {
					router.push("/perfil");
				}, 3000);

			}).catch((error) => { setWaiting(false); setSuccess(false); })
	}).catch((error) => {
		setWaiting(false);
		setSuccess(false);
		const message = error?.response?.data?.message;
		if (message === "Some user information is already in use!" || message === "Error in CNPJ API") {
			toast?.error("CNPJ ou CPF j� cadastrado");
		}
		toast?.error("Voc� precisa preencher todos os campos corretamente");
	});
}

export const requestUserData = async ({ endpoint, token }: Props) => {
	try {
		const response = await axios.get(endpoint, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		return response.data;

	} catch (error) {
		console.log(error);
	}


}